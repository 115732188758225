const state = {
  idVacCampaign: null,
  idVacCompany: null,
  idVacUnity: null,
  vacDescricaoCampanha: null,

  search: {
    unidade: '',
    empresa: '',
    campanha: '',
    endereco: '',
  },
}

const mutations = {
  SET_ID_CAMPAIGN(state, data) {
    state.idVacCampaign = data
  },
  SET_ID_COMPANY(state, data) {
    state.idVacCompany = data
  },
  SET_ID_UNITY(state, data) {
    state.idVacUnity = data
  },
  SET_DESCRICAO_CAMPANHA(state, data) {
    state.vacDescricaoCampanha = data
  },

  CLEAR_SEARCH(state) {
    state.search = {
      unidade: '',
      empresa: '',
      campanha: '',
      endereco: '',
    }
  },
}

const actions = {
  setDescricaoCampanha: ({ commit }, payload) => {
    commit('SET_DESCRICAO_CAMPANHA', payload)
  },
  setIdUnity: ({ commit }, payload) => {
    commit('SET_ID_UNITY', payload)
  },
  setIdCompany: ({ commit }, payload) => {
    commit('SET_ID_COMPANY', payload)
  },
  setIdCampaign: ({ commit }, payload) => {
    commit('SET_ID_CAMPAIGN', payload)
  },
}

const getters = {
  getSearch: state => state.search,
  getIdCampaign: state => state.search.campanha ? state.search.campanha.id_campanha : null,
  getIdCompany: state => state.search.empresa ? state.search.empresa.id_empresa : null,
  getIdUnity: state => state.search.unidade ? state.search.unidade.id_unidade : null,
  getDescricaoCampanha: state => state.vacDescricaoCampanha,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
