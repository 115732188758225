const state = {
    companyGroup: [],
    companyGroupFilter: [],
    idCompanyGroup: null,
    companyEditWhenCreatingGroup: null,
    companyEditWhenEditingGroup : null,
    companyEditIdEmpresa : [],
    companyWithOperators: [],
}

const mutations = {
    SET_COMPANY_GROUP_STATE(state, data){
        const companyExistsIndex = state.companyGroup.findIndex(company => company.id_empresa == data.id_empresa)
        if(companyExistsIndex != -1) {
            state.companyGroup[companyExistsIndex].operator = data.operator
            state.companyGroup[companyExistsIndex].descricao_operador = data.descricao_operador 
            state.companyGroup[companyExistsIndex].sigla_operador = data.sigla_operador

        } else {
            state.companyGroup.push(data)
        }
    },

    SET_COMPANY_GROUP_FILTER_STATE(state, data) {
        state.companyGroupFilter = data
    },

    SET_COMPANY_GROUP_ARRAY_STATE(state, data){
        state.companyGroup = data;
    },

    SET_ID_COMPANY_GROUP(state, data){
        state.idCompanyGroup = data
    },

    RESET_COMPANY_GROUP(state) {
        state.companyGroup = []
    },

    SET_COMPANY_EDIT_WHEN_CREATING_GROUP(state, data) {
        state.companyEditWhenCreatingGroup = data
    },

    RESET_COMPANY_EDIT_WHEN_CREATING_GROUP(state) {
        state.companyEditWhenCreatingGroup = null
    },

    SET_COMPANY_EDIT_WHEN_EDITING_GROUP(state, data) {
        state.companyEditWhenEditingGroup = data
    },

    RESET_COMPANY_EDIT_WHEN_EDITING_GROUP(state) {
        state.companyEditWhenEditingGroup = null
    },

    SET_COMPANY_EDIT_ID_EMPRESA(state, data) {
        state.companyEditIdEmpresa.push(data)
    },

    RESET_COMPANY_EDIT_ID_EMPRESA(state) {
        state.companyEditIdEmpresa = []
    },

    SET_COMPANY_WITH_OPERATORS(state, data) {
        state.companyWithOperators.push(data)

        state.companyGroup.forEach(companyGroup => {
            if(companyGroup.id_empresa == data.id_empresa){
                companyGroup.operator.forEach(operator => {
                    const exists =  state.companyWithOperators.some(item => item.id_empresa_endereco === data.id_empresa_endereco)
                        if(!exists) {
                            state.companyWithOperators.push({
                                ...operator,
                                descricao: data.descricao,
                                id_departamento: data.id_departamento,
                                id_empresa: data.id_empresa,
                                id_empresa_endereco: data.id_empresa_endereco,
                                id_unidade: data.id_unidade,
                                nome_cidade: data.nome_cidade,
                                uf: data.uf
                            })
                        }

                })
            }
        })

    }
}

const actions = {
  
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
